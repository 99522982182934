import React from 'react';

import './Header.css'
import PersonIcon from '@material-ui/icons/Person'
import ForumIcon from '@material-ui/icons/Forum'
import {IconButton} from "@material-ui/core";

function Header(props) {
    return (
        <div className="header">
            <IconButton> <PersonIcon className='header_icon'/></IconButton>
            <IconButton> <ForumIcon className='header_icon'/></IconButton>
        </div>
    );
}

export default Header;