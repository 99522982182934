import axios from "axios";

var format = require("string-template")

const botToken = '7480255820:AAHxU_Mu74DGX175ESriwn-xYNrxx9Vi8hM'


export async function getPhoto(user_id) {
    return axios.post(format('https://api.telegram.org/bot{botToken}/getUserProfilePhotos', {botToken: botToken}), {
        user_id: user_id
    }).then(response => {
        const resp = response.data.result
        return resp.photos[0][0].file_id;
    });
}


export async function getFilePath(fileId) {
    return axios.post(format('https://api.telegram.org/bot{botToken}/getFile', {botToken: botToken}), {
        file_id: fileId
    }).then(response => {
        const file = response.data.result
        const photoPath = file.file_path
        return format('https://api.telegram.org/file/bot{botToken}/{photoPath}', {
            botToken: botToken,
            photoPath: photoPath
        })
    });
}