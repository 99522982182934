import React, {useState} from 'react';
import TinderCard from "react-tinder-card";
import './TinderCards.css'

function TinderCards(props) {
    const [people, setPeople] = useState([{
        name: "test1",
        url: 'https://st4.depositphotos.com/12982378/21866/i/450/depositphotos_218669658-stock-photo-portrait-beautiful-woman-sweater-sitting.jpg'
    }, {
        name: "test2",
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpiRrSgDtFoID3xahzV3s4CTOgidy96M9OyQ&s'
    }])
    return (
        <div>
            <div className="tinderCard_container">
                {people.map(person => (<TinderCard className="swipe" key={person.name}>
                    <div style={{backgroundImage: `url(${person.url})`}} className="tinderCard"><h3>{person.name}</h3>
                    </div>
                </TinderCard>))}
            </div>
        </div>
    );
}

export default TinderCards;