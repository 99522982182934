import React, {useState} from 'react';
import {Link} from "react-router-dom";
import "./Surway.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Surway(props) {
    const [birthday, setBirthday] = useState(new Date());
    const [lookingForAge, setLookingForAge] = useState("");
    const [location, setLocation] = useState("");
    const [bio, setBio] = useState("");
    const handleBirthday = (e) => {
        setBirthday(e.target.value);
    };

    const handleLookingForAge = (e) => {
        setLookingForAge(e.target.value);
    };


    const handleLocation = (e) => {
        setLocation(e.target.value);
    };

    const handleBio = (e) => {
        setBio(e.target.value);
    };

    return (
        <div className={"Surway_form"}>
            <div>
                <h1>User Registration</h1>
            </div>

            <label className="label">Bio</label>
            <input
                onChange={handleBio}
                className="input"
                value={bio}
                type="text"
            />
            <label className="label">birthday</label>
            <DatePicker selected={birthday} onChange={(date) => setBirthday(date)}/>

            <label className="label">location</label>
            <input
                onChange={handleLocation}
                className="input"
                value={location}
                type="text"
            />
            <p>Please specify your gender:</p>
            <input type="radio" id="html" name="fav_language" value="Male"/>
            <label htmlFor="html">Male</label><br/>
            <input type="radio" id="css" name="fav_language" value="Female"/>
            <label htmlFor="css">Female</label><br/>
            <Link to="/photoupload">
                <button className="btn" type="submit">
                    Submit
                </button>
            </Link>
        </div>
    );
}

export default Surway;