import React, {useEffect, useState} from 'react';
import "./RegisterScreen.css"
import axios from "axios";
import {getFilePath, getPhoto} from "./utils/telegram_api";
import {Link} from "react-router-dom";


const RegisterScreen = () => {
    const [photo, setPhoto] = useState('')
    const [username, setUsername] = useState('')
    let wb = {}
    useEffect(() => {
            /*global Telegram */
            if (Telegram.WebApp.initDataUnsafe.user) {
                wb = Telegram.WebApp.initDataUnsafe.user
                getPhoto(wb.id).then(file_id => {
                    getFilePath(file_id).then(photo => {
                        wb.photo = photo
                        setPhoto(wb.photo)
                        setUsername(wb.first_name)
                    })
                })

            } else {
                wb = {
                    first_name: 'TEST',
                    photo: 'https://wallpapers.com/images/featured/cool-men-d7ag46klh1n45y8b.jpg'
                }
                setPhoto(wb.photo)
                setUsername(wb.first_name)
            }
        }
    )


    return (
        <div>
            <div><p>Привет {username}</p></div>
            <div>
                <Link to="/surway">
                    <button>Register</button>
                </Link>
            </div>
            <div className="register_screen_main" style={{
                backgroundImage: `url(${photo})`,
                backgroundSize: `contain`,
                backgroundRepeat: `no-repeat`
            }}></div>

        </div>
    );
};

export default RegisterScreen;