import logo from './logo.svg';
import './App.css';
import Header from "./Header";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import TinderCards from "./TinderCards";
import {createContext, useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import RegisterScreen from "./RegisterScreen";
import useWebApp from "./useWebApp";
import Surway from "./Surway";
import PhotoUpload from "./PhotoUpload";

function App() {

    const c = useWebApp()
    return (
        <div className="App">
            <Header/>
            <Router>
                <Routes>
                    <Route path={"/chat"}>
                        I am chat
                    </Route>
                    <Route path={'/'} element={<RegisterScreen/>}/>
                    <Route path={'/surway'} element={<Surway/>}/>
                    <Route path={'/dating'} element={<TinderCards/>}/>
                    <Route path={'/photoupload'} element={<PhotoUpload/>}/>
                </Routes>
            </Router>

        </div>
    );
}

export default App;
